import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { motion } from 'framer-motion';

import '../styles/global.css';

import DirtLayout from '../components/DirtLayout';
import Seo from '../components/Seo';
import DirtLinks from '../components/DirtLinks';

export default function OtherServices() {
   return (
      <DirtLayout>
         <Seo title='Other Services' />
         <OtherServicesHeader />
         <DirtLinks />
         <section className='text-gray-600 body-font'>
            <div className='container px-5 py-24 mx-auto'>
               <div className='flex flex-col'>
                  <div className='h-1 bg-gray-200 rounded overflow-hidden'>
                     <div className='w-24 h-full bg-dirt'></div>
                  </div>
                  <div className='flex flex-wrap sm:flex-row flex-col py-6 mb-12'>
                     <h1 className='sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0'>We Also Do a Whole Lot More</h1>
                     <p className='sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0'>
                        Don't see a service here that you need? Changes are we will still be able to help. We do so much more than whats listed, just
                        reach out to us and we'll see how we can help.
                     </p>
                  </div>
               </div>
               <div className='flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4'>
                  <div className='p-4 md:w-1/3 sm:mb-0 mb-6'>
                     <div className='rounded-lg h-64 overflow-hidden shadow-lg'>
                        <StaticImage
                           alt='Front end loader moving a boulder'
                           className='object-cover object-center h-full w-full '
                           src='../images/Hunter-Environmental-Other-Services-Boulder.jpg'
                        />
                     </div>
                     <h2 className='text-xl font-medium title-font text-gray-900 mt-5'>Boulder Removal</h2>
                     <p className='text-base leading-relaxed mt-2'>
                        Need boulders removed? We do that! We can come to you, load, and dispose of boulders up to 20,000lb.
                     </p>
                     <AnchorLink to='/dirt#contact' className='text-dirt inline-flex items-center mt-3'>
                        Learn More{' '}
                        <svg
                           fill='none'
                           stroke='currentColor'
                           stroke-linecap='round'
                           stroke-linejoin='round'
                           stroke-width='2'
                           className='w-4 h-4 ml-2'
                           viewBox='0 0 24 24'
                        >
                           <path d='M5 12h14M12 5l7 7-7 7'></path>
                        </svg>
                     </AnchorLink>
                  </div>
                  <div className='p-4 md:w-1/3 sm:mb-0 mb-6'>
                     <div className='rounded-lg h-64 overflow-hidden shadow-lg'>
                        <StaticImage
                           alt='Front end loader moving a shed'
                           className='object-cover object-center h-full w-full'
                           src='../images/Hunter-Environmental-Other-Services-Shed.jpg'
                        />
                     </div>
                     <h2 className='text-xl font-medium title-font text-gray-900 mt-5'>Shed Moving</h2>
                     <p className='text-base leading-relaxed mt-2'>
                        Need a shed moved? We do that! Whether it's across the yard or across the state we can move your shed safely.
                     </p>
                     <AnchorLink to='/dirt#contact' className='text-dirt inline-flex items-center mt-3'>
                        Learn More{' '}
                        <svg
                           fill='none'
                           stroke='currentColor'
                           stroke-linecap='round'
                           stroke-linejoin='round'
                           stroke-width='2'
                           className='w-4 h-4 ml-2'
                           viewBox='0 0 24 24'
                        >
                           <path d='M5 12h14M12 5l7 7-7 7'></path>
                        </svg>
                     </AnchorLink>
                  </div>
                  <div className='p-4 md:w-1/3 sm:mb-0 mb-6'>
                     <div className='rounded-lg h-64 overflow-hidden shadow-lg'>
                        <StaticImage
                           alt='Excavator working on a tree'
                           className='object-cover object-center h-full w-full'
                           src='../images/Hunter-Environmental-Land-Clearing-Feature-2.jpg'
                        />
                     </div>
                     <h2 className='text-xl font-medium title-font text-gray-900 mt-5'>Tree Work</h2>
                     <p className='text-base leading-relaxed mt-2'>
                        Need trees removed? We do that! From intricate tree work to land clearing we have you covered.
                     </p>
                     <AnchorLink to='/dirt#contact' className='text-dirt inline-flex items-center mt-3'>
                        Learn More{' '}
                        <svg
                           fill='none'
                           stroke='currentColor'
                           stroke-linecap='round'
                           stroke-linejoin='round'
                           stroke-width='2'
                           className='w-4 h-4 ml-2'
                           viewBox='0 0 24 24'
                        >
                           <path d='M5 12h14M12 5l7 7-7 7'></path>
                        </svg>
                     </AnchorLink>
                  </div>
               </div>{' '}
               <p className='leading-relaxed text-base mt-12'>And more, including catch basin repair and landscape construction.</p>
            </div>
         </section>
      </DirtLayout>
   );
}

const OtherServicesHeader = () => {
   return (
      <div className='grid'>
         <StaticImage
            style={{ gridArea: '1/1' }}
            layout='fullWidth'
            alt='Hunter Environmental Excavator digging for septic systems'
            src='../images/Hunter-Environmental-Equipment-Excavator.jpg'
            formats={['auto', 'webp', 'avif']}
            className='h-128'
         />
         <div aria-hidden='true' style={{ gridArea: '1/1' }} className='bg-gradient-to-b from-black opacity-80 relative inset-0 z-10' />
         <div className='grid relative items-center w-full z-20' style={{ gridArea: '1/1' }}>
            <div className='w-full h-fit'>
               <div className='flex flex-col justify-center items-center md:items-start max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:px-0'>
                  <div className='text-4xl lg:text-6xl text-center md:text-left md:pl-2 my-6 md:border-l-8 md:border-dirt font-bold text-white'>
                     <motion.h1
                        initial={{ opacity: 0, y: -15 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        className='mb-4 text-center md:text-left'
                     >
                        Other
                     </motion.h1>
                     <motion.h1 initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
                        Services
                     </motion.h1>
                  </div>

                  <AnchorLink
                     to='/dirt#contact'
                     style={{ fontFamily: 'Muli' }}
                     className='w-max md:ml-4 shadow-lg mt-8 bg-dirt hover:bg-dirt-light  text-xs md:text-base rounded-md text-black px-12 py-2'
                  >
                     Contact Us
                  </AnchorLink>
               </div>
            </div>
         </div>
      </div>
   );
};
